import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: '', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Konstantinos Kourouklidis',
  subtitle: "I'm a frontend web developer",
  cta: 'About me',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: 'Front-end Developer with a strong CS background. Great interest in my field of study and highly motivated to extend my work experience on it. Professional experience with some of the most famous front-end frameworks such as React and Angular2. Changed my career from Sales Staff to Web Developer by educating myself. Analytical minded, focused to the New Technologies and very happy to learn by doing. Skilled in various front-end tools and technologies, capable of translating ideas into working solutions.',
  paragraphTwo: '',
  paragraphThree: '',
  resume: 'CVkourouklidis.png', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'Olkis.gr',
    info: 'static Webpage made with gatsbty, still work in progress but the performance results are fascinating! ',
    info2: '',
    url: 'https://olkis.gr/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'book.PNG',
    title: 'MovieBook',
    info: 'movie book is an app that shows info for movies and series, powered from the tmdb api',
    info2: 'still work in progress...',
    url: 'https://react-moviebook-app.pages.dev/',
    repo: 'https://github.com/kokoyroy/react-moviebook-app', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'webdev.JPG',
    title: 'svg animation',
    info: 'experimenting with svg animations ',
    info2: 'still work in progress...',
    url: 'https://typographyanimation.pages.dev/',
    repo: 'https://github.com/kokoyroy/typographyAnimation', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'tetrisReact.JPG',
    title: 'react Tetris',
    info: 'classic tetris game, made(not yet) in react.js',
    info2: 'WORK IN PROGRESS !',
    url: 'https://react-tetris.pages.dev/',
    repo: 'https://github.com/kokoyroy/react-tetris', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'kokoyroy@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/kokoyroy',
    },
    {
      id: nanoid(),
      name: 'codepen',
      url: 'https://codepen.io/kostas-kourouklidis',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/kourouklidis/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/kokoyroy',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
